@import '../../../node_modules/@fontsource-variable/inter';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  overflow-x:hidden;
}

body {
  font-family: 'Inter Variable', sans-serif;
  @apply text-white;
}

code {
  font-family: 'Source Code Pro', monospace;
}
